@import "../../styles/variables.scss";
/*--------------------------------------------------------------
# Gigs
--------------------------------------------------------------*/
$brandColour: white;
$brandColourTint: #191a1f;
$brandColourLight: #434856;

.gigs {
  min-height: 80vh;
}

.gigs__future-gigs-container {
  margin-bottom: 80px;
}

.gig-meta {
  color: $brandColour;
}

.gig-container {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 0 20px 0;
  padding: $spacingSmall;
  border-bottom: 1px solid $brandColour;
}
.gig__tickets {
  display: flex;
  color: $brandColour;
}

.gig__tickets-href {
  padding: 10px 18px;
  border: 1px solid $brandColour;
  border-radius: 4px;
  color: $brandColour;
  transition: 0.3s;
  flex: 2;
  text-align: center;
  text-decoration: none;
}

.gig__tickets-href:visited {
  color: $brandColour;
}

.gig__tickets-href:hover {
  background-color: $brandColourLight;
  color: $brandColour;
  transition: 0.3s;
  text-decoration: none;
}

@media (max-width: 600px) {
  .gig__title {
    text-align: center;
    margin: 10px;
  }

  .gig__venue-location {
    text-align: center;
    margin: 6px;
  }
  .gig__date-time {
    text-align: center;
    margin-top: 4px;
    margin-bottom: 16px;
  }
  .gig__tickets-href {
    margin-left: 0;
    margin-right: 0;
  }

  .gig__tickets {
    display: flex;
    justify-content: center;
  }
}

/*--------------------------------------------------------------
# Pinned Gigs
--------------------------------------------------------------*/

.pinned {
  background-color: $brandColourLight;
  border-radius: $borderRadius;
  border-bottom: 0;

  .gig__tickets-href:hover {
    background-color: $brandColourTint;
  }
}

.pinned-gig-icon {
  position: absolute;
  right: -4px;
  top: -12px;
  rotate: 28deg;
  color: $brandColour;
}

.pinned-gig-icon::after {
  content: "";
  display: flex;
  width: 4px;
  height: 4px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 19px;
  right: 10px;
  border-radius: 10px;
  z-index: -1;
}
