/* Fonts */
@font-face {
  font-family: roman_do;
  src: url(../fonts/day_roman/DAYROM.ttf);
}

@font-face {
  font-family: helvetica_neue_bold;
  src: url(../fonts/helvetica/HelveticaNeueLTPro-Bd_0.ttf);
}
$brand: #333133;
$brandTint: #ececec;
$lightGrey: #f1f1f1;
$darkGrey: #29292a;
$white: #fff;
$emphais: hotpink;

$spacingSmall: 0.75rem;
$spacingStandard: 1rem;
$spacingLarge: 1.5rem;
$spacingVeryLarge: 2rem;

$borderRadius: 3px;

@font-face {
  font-family: helvetica_neue_light;
  src: url(../fonts/helvetica/HelveticaNeueLTPro-Lt_0.ttf);
}

.main-font {
  font-family: Heebo, sans-serif;
}

.--bold {
  font-weight: bold;
}

.--white {
  color: white;
}
.--brand {
  color: $brand;
}
.--capitalize {
  text-transform: uppercase;
}
