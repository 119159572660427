/* Homepage*/

.wrest {
  font-family: roman_do, times, serif;
  text-transform: lowercase;
  font-size: 70px;
  margin: 0;
  display: inline;
  font-weight: 100;
  letter-spacing: -2px;
  padding: 0;
}

.coward-title {
  font-family: roman_do, times, serif;
  text-transform: lowercase;
  font-size: 3em;
  margin-top: 0;
  margin-bottom: 5px;
  display: inline;
  font-weight: 100;
  padding-left: 15px;
  padding-right: 15px;
  letter-spacing: -3px;
  border-bottom: 1px solid #454547;
}

.social-media {
  position: absolute;
  bottom: 26px;
  right: 0;
  z-index: 10;
  display: flex;
}

.social-media__href {
  height: 32px;
  width: 32px;
}

.social-media:hover .social-media__href:not(:hover) svg path,
.social-media:hover .social-media__href:not(:hover) svg circle {
  fill: #474747 !important;
  transition: 0.6s ease-in;
}

.social-media__href:not(:last-child) svg path,
.social-media__href:not(:last-child) svg circle {
  fill: #b8b8b8 !important;
  transition: 0.6s ease-in;
}

.social-media__href:not(:last-child) {
  margin-right: 12px;
}

.home,
.home article,
.home .entry-content {
  margin: 0 !important;
}

.tagline {
  font-size: 22px;
  padding-left: 6px;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-decoration: none;
  &.--link {
    background: linear-gradient(90deg, #fdfcfc 50%, #191e2300 0);
    background-size: 200% 100%;
    background-position: 100% 100%;
    margin-left: 10px;
    transition: all 2s ease;
    color: white;
    border: 1px solid;
    padding: 6px;

    &:hover {
      background-position: 0% 100%;
      transition: 0.3s ease-in;
      background-size: 200% 100%;
      color: #343a40;
      border-color: white;
      text-decoration: none;
    }
  }
}

#content-header-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  top: 0;
  min-height: 380px;
}

#content-header-container {
  overflow: hidden;
  min-height: 100%;
  position: relative;
}

#content-header-text {
  margin: 0 auto;
  height: 100%;
  width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  color: white;
  position: relative;
}

#content-header-video {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -100;
}

video {
  position: absolute;
  top: 20%;
  left: 80%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  -ms-transform: translateX(-80%) translateY(-20%);
  transform: translateX(-80%) translateY(-20%);
}

#text-wrapper {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home__section {
  bottom: 26px;
  width: 100%;
  display: flex;
  align-items: center;
}

.home__sound-wrapper {
  bottom: 26px;
  position: absolute;
  z-index: 20;
}

.home__sound-wrapper:hover {
  cursor: pointer;
}

#content-body {
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  #content-header-text {
    align-items: center;
  }

  .social-media {
    justify-content: center !important;
  }
}

@media (max-width: 600px) {
  .tagline {
    text-align: center;
  }

  .home__sound-wrapper {
    top: 12px;
  }

  .social-media {
    bottom: 12px;
  }
}

/*
* ==============================================================
* Reduce Sizes for really small mobile devices
* ==============================================================
 */

@media (max-width: 600px) {
  .wrest {
    font-size: 40px;
  }

  .tagline {
    font-size: 12px;
  }

  .social-media__href {
    svg {
      height: 24px;
    }
  }
}
