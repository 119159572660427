@import "../../styles/variables.scss";
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  padding: 20px 60px;
  display: flex;

  .copyright {
    color: $brand;
    margin: 0;

    .wrest {
      font-size: 20px;
      letter-spacing: -1px;
    }
  }
}

.social-media-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
}

.footer-wrapper {
  width: 100%;
}

@media (min-width: 768px) {
  .footer {
    flex-direction: row-reverse;

    .copyright {
      text-align: right;
    }
  }
  .social-media-footer {
    align-items: flex-end;
    justify-content: flex-end;
  }
}

@media (max-width: 767.5px) {
  .social-media-footer {
    justify-content: center;
  }

  .copyright {
    text-align: center;
  }
}
