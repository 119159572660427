@import "../../styles/variables.scss";
/*--------------------------------------------------------------
# Site Navigation
--------------------------------------------------------------*/
.nav ul li {
  margin-right: 20px;
}

.menu {
  display: flex;
}

.menu-item > a {
  color: $brand;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.25rem;
  letter-spacing: 3px;
  transition: 0.3s;
  text-decoration: none;
}

.nav ul li:not(:last-child) {
  margin-right: 20px;
}

.nav ul li a:hover {
  border-bottom: 2px;
  border-bottom-style: solid;
}

.--selected a {
  border-bottom: 2px;
  border-bottom-style: solid;
}

.current-menu-item {
  a {
    color: $brand;
  }
}

.site-header {
  position: fixed;
  top: 0;
  z-index: 5;
  width: 100%;
  display: flex;
  align-items: center;
  &.--background {
    background: $lightGrey;
  }
  &.--home {
    .menu-item > a {
      color: white;
    }
    .slice {
      background: white;
    }
    .mobile-nav {
      .menu-item > a {
        color: $brand;
      }
    }
  }
}

.custom-logo {
  width: 120px;
  height: auto;
  padding: 10px 0;
}

.main-navigation {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  min-height: 60px;
  align-items: center;
}

.main-navigation ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.nav-brand {
  width: 106px;
}

/*
 = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
 HAMBURGER and navigation controls on collapse - - - - - - -
 = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
 */

@media (min-width: 600.5px) {
  .hamburger {
    display: none;
  }
  .mobile-nav {
    display: none;
  }
}

@media (max-width: 600px) {
  .hamburger {
    width: 32px;
    cursor: pointer;
    margin-right: 10px;
    background: 0;
    border: 0;
    padding: 0;
  }

  .menu {
    display: none;
  }

  .close {
    width: 32px;
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
    border: 0;
    background: 0;
    height: 30px;
    padding: 0;
  }

  .close-slice {
    height: 4px;
    width: 100%;
    background: $brand;
    position: absolute;
  }

  .close-slice:first-child {
    transform: rotate(45deg);
  }

  .close-slice:nth-child(2) {
    transform: rotate(-45deg);
  }

  .nav-active {
    opacity: 1 !important;
    z-index: 22 !important;
    visibility: visible !important;
  }

  .slice {
    height: 4px;
    width: 100%;
    background: $brand;
    margin-bottom: 4px;
    border-radius: 1px;
  }

  .mobile-nav {
    height: 100vh;
    position: fixed;
    background-color: white;
    width: 100%;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    display: flex;
    min-height: 580px;
    align-items: center;
    transition: 0.3s ease-in;
    top: 0;
    left: 0;

    ul {
      list-style: none;
    }

    li {
      padding-bottom: $spacingSmall;
    }
  }

  .menu-item > a {
    color: $brand;
  }
}

.entry-content {
  color: $brand;
}
