.heading__gigs {
  color: white;
}

.heading__previous-gigs {
  margin-top: 60px;
  color: white;
}

.gigs__image {
  display: flex;
  justify-content: center;
  img {
    max-width: 400px;
  }
}

@media (max-width: 600px) {
  .gigs__image {
    img {
      max-width: 300px;
    }
  }
}
